import React from 'react';
import "./Header.css";
import SearchIcon from "@material-ui/icons/Search";
import HomeIcon from '@material-ui/icons/Home';
import FlagIcon from '@material-ui/icons/Flag';
import SubscriptionsOutlinedIcon from '@material-ui/icons/SubscriptionsOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { Avatar } from '@material-ui/core';
import { IconButton } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import ForumIcon from '@material-ui/icons/Forum';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStateValue } from "./StateProvider";

function Header() {

  const [{ user }, dispatch] = useStateValue();

  return (
    <div className="header">
      <div className="header__left">
{  }
      <img
        src = "https://i.ibb.co/ww08t1Y/carsonbookicon-finalblue.png" alt=""
        />
         

        <div className="header__input">
          <SearchIcon />
          <input placeholder='Search Carsonbook' type="text" />

          </div>
        </div>

      <div className="header__center">
        <div className="header__option
        header__option--active">
          <HomeIcon font-size="large" />
        </div>
        <div className="header__option">
          <FlagIcon font-size="large" />
        </div>
        <div className="header__option">
          <SubscriptionsOutlinedIcon font-size="large" />
        </div>
        <div className="header__option">
          <StorefrontOutlinedIcon font-size="large" />
        </div>
        <div className="header__option">
          <SupervisedUserCircleIcon font-size="large" />
        </div>
        </div>
        
      <div className="header__right">
        <div className="header__info">
        <Avatar src={user.photoURL} />
        <h4>{user.displayName}</h4>
        </div>

        <IconButton>
          <AddIcon />
        </IconButton>
        <IconButton>
          <ForumIcon />
        </IconButton>
        <IconButton>
          <NotificationsActiveIcon />
        </IconButton>
        <IconButton>
          <ExpandMoreIcon />
        </IconButton>
        </div> 
        </div>         
 
 
  
  );
    
}

export default Header;
