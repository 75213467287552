import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDyMq6HncBj0yOqQYNc1rPNYq0DhKMnrUw",
    authDomain: "facebook-clone-a6d5f.firebaseapp.com",
    databaseURL: "https://facebook-clone-a6d5f.firebaseio.com",
    projectId: "facebook-clone-a6d5f",
    storageBucket: "facebook-clone-a6d5f.appspot.com",
    messagingSenderId: "966763740181",
    appId: "1:966763740181:web:cbc98d2fd2a7bcde94a21c",
    measurementId: "G-PMK159LD2V"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { auth, provider};
export default db;
