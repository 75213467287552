import React from 'react';
import Story from "./Story"
import "./StoryReel.css";

function StoryReel() {
    return (
        <div className="storyReel">
            <Story 
            image="http://carsonh.com/CBReact/winstonstand.PNG"
            profileSrc="http://carsonh.com/CBReact/me2020.transparent.JPG"
            title="Carson"
            />
            <Story 
            image="http://carsonh.com/CBReact/winstonagility.PNG"
            profileSrc="http://carsonh.com/CBReact/winstonicon.PNG"
            title="Wihston"
            />
            <Story 
            image="http://carsonh.com/CBReact/squirrel.jpg"
            profileSrc="http://carsonh.com/CBReact/noimage.jpg"
            title="Nutsy"
            />
             <Story 
            image="http://carsonh.com/CBReact/Pookie.jpg"
            profileSrc="http://carsonh.com/CBReact/noimage.jpg"
            title="Pookie"
            />


            
        </div>
    )
}

export default StoryReel
