import React from 'react';
import "./Sidebar.css";
import SidebarRow from './SidebarRow';

import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags";
import PeopleIcon from "@material-ui/icons/People";
import ChatIcon from "@material-ui/icons/Chat";
import StoreFrontIcon from "@material-ui/icons/Storefront";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import { useStateValue} from "./StateProvider";



function Sidebar() {
    
    const [{ user }, dispatch] = useStateValue();
    return (
        <div classname='sidebar'>
            {/* <SidebarRow src={user.photoURL} title={user.displayName} /> */}
            {/* old winstonpic <SidebarRow src='https://i.ibb.co/mTZgRPG/winstonicon.png' title="Winston" /> */}
            <SidebarRow src={user.photoURL} title={user.displayName} />
            <SidebarRow Icon={EmojiFlagsIcon} title='Pages'/> 
            <SidebarRow Icon={PeopleIcon} title='Friends'/> 
            <SidebarRow Icon={ChatIcon} title='Messenger'/> 
            <SidebarRow Icon={StoreFrontIcon} title='Marketplace'/> 
            <SidebarRow Icon={VideoLibraryIcon} title='Videos'/> 
            {/* <SidebarRow Icon={ExpandMoreOutlined} title='Marketplace'/>  */}
            
            
        </div>
    )
}

export default Sidebar;
