import { Button } from '@material-ui/core';
import React from 'react';
import './Login.css';
import { Helmet } from 'react-helmet';
import { auth, provider } from "./firebase";
import { actionTypes} from "./reducer";
import { useStateValue } from "./StateProvider"

function Login() {
    const [state, dispatch] = useStateValue();

    const signIn = () => {
        // sign in stuff
        
        auth.signInWithPopup(provider)
        .then((result)  => {

            dispatch({
                type: actionTypes.SET_USER,
                user: result.user,
            });
        })
        .catch((error) => alert(error.message));
        
    };
    
    return (
        <div className="login">
            <div className="login__logo">
            <Helmet>
        <title>CarsonBook</title>
      </Helmet>

            <img
            src="https://i.ibb.co/ww08t1Y/carsonbookicon-finalblue.png"
            alt=""
            />
            <img src="http://carsonh.com/carsonbam/images/carsonbook_beta.PNG"
            alt=""
            />
            
        </div>
        <Button type="submit" onClick={signIn}
        >
            Log In
        </Button>
    </div>
    )
}

export default Login
