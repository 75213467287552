import React from 'react';
import './App.css';
import Header from "./Header";
import Feed from "./Feed";
import Login from "./Login";
import Sidebar from './Sidebar';
import Widgets from './Widgets';
import { useStateValue } from "./StateProvider";

  

function App() {
  const [{ user }, dispatch] = useStateValue();

  return (
    <div className="app">
      {!user ? (
        <Login />
      ) : (
        <>
      <Header />

      <div className="app__body">
        <Sidebar />
        <Feed />
        <Widgets />
      
      
      {/* Widgets */}

      </div>
      </>
      )}
      
    </div>
    
  );
}

export default App;

