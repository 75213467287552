import React from 'react';
import "./Widgets.css";


function Widgets() {
    return (
        <div className="widgets">
            {/* <iframe title="CorgiWinston" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcorgiwinston%2F&tabs=timeline&width=340&height=1500&small_header=false&adapt_container_width=true@hide_cover=false&show_facepile=true&appId"  */}
            {/* <iframe title = "CarsonH" src="https://tinyurl.com/yy52bxy4"  */}
            <iframe title = "CarsonH" src="https://www.carsonh.com/CarsonBook_Welcome/" 

            width="300"
            height="100%"
            small_header="false"
            tabs="timeline"
            adapt_container_width="true"
            hide_cover="false"
            show_facepile="true"
            style={{ border: "none", overflow: "hidden"}}
            scrolling="no"
            frameborder="0"
            allowTransparency="true"
            allow="encrypted-media"
            // position="fixed" !important
            
            ></iframe>
            
        </div>
    );

}

export default Widgets;
