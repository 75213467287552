import React from 'react';
import './Story.css';
import { Avatar } from '@material-ui/core';



function Story({ image, profileSrc, title}) {

    return (

        // statement below is ES5
        // ES6 is backgroundImage: `url(${image})`
        <div style={{ backgroundImage: "url(" + image + ")" }}
            className="story">
            <Avatar classname="story__avater" src={profileSrc} />
            <h4>{title}</h4>
            
        </div>
    )
}

export default Story;
